import './header.css'

function Header() {
    return (
        <header className="App-header">
            <h1>Jeu du pendu</h1>
        </header>
    );
    }

export default Header;